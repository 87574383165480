import Button from '../../../Button';
import {
  IAccountSignedOut,
} from '../../../../interfaces/components/accountSelector.interface';

import Circular from '../../../CircularBrandLogo';

const AccountSignedOut = ({
  sessionId,
  deviceId,
  username,
  brand,
  logo,
  make,
  signedOutText,
  actionButtons,
  drawerOpen,
  openDrawer,
  handleReturnUserAction,
}: IAccountSignedOut) => {
  const handleAccountKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      openDrawer(drawerOpen ? '' : sessionId);
    }
  };

  return (
    <div
      className="account"
      role="button"
      tabIndex={0}
      onClick={() => openDrawer(drawerOpen ? '' : sessionId)}
      onKeyDown={handleAccountKeyDown}
      aria-expanded={drawerOpen}
      aria-haspopup
    >
      <div className="account-info">
        <Circular make={make} logo={logo} displayName={brand} />
        <div className="text">
          <div className="brand">
            <h3>{brand}</h3>
            <span>{signedOutText}</span>
          </div>
          <div className="username">
            <p>
              {username}
            </p>
            <img
              src="/assets/images/icons/chevron.svg"
              style={drawerOpen ? { transform: 'rotate(180deg)' } : {}}
              alt=""
            />
          </div>
        </div>
      </div>
      {drawerOpen && (
        <div className="account-drawer button-set-wrapper">
          {actionButtons.map(({
            action, additionalClassNames, elementAttributes, text,
          }) => {
            return (
              <Button
                key={text}
                additionalClassNames={additionalClassNames}
                elementAttributes={elementAttributes}
                text={text}
                handleClick={() => handleReturnUserAction({ action, deviceId, sessionId })}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AccountSignedOut;
