import { formatIconPath } from '../../contexts/Theme';
import { unallowedBrandLogos } from '../../utils';

import { IProps } from '../../interfaces/components/circularBrandLogo';

const CircularBrandLogo = (
  { make, displayName, logo }: IProps,
) => {
  const renderBrandLogo = () => {
    if (unallowedBrandLogos.includes(make)) {
      return (
        <div className="circular-brand-logo-fallback">{make[0]}</div>
      );
    }
    return (
      <img
        src={formatIconPath(logo)}
        alt={`${displayName} logo`}
        className="circular-brand-logo"
      />
    );
  };

  return (
    <div className="circular-brand-logo-container">
      {renderBrandLogo()}
    </div>
  );
};

export default CircularBrandLogo;
