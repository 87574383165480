/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import api from '../../services/api';
import errorHandler from '../../services/sentry';
import { stripTrailingSlashes } from '../../utils';

import Page from '../Page';
import {
  Box,
  Button,
  CustomBulletListItem,
  Form,
  Header,
  Image,
  InlineStyledText,
  Link,
  List,
  ListSection,
  ListItem,
  Paragraph,
} from '../../components';

import { IPageProps } from '../../interfaces/pages/page.interface';

const componentMap = {
  button: Button,
  customBulletListItem: CustomBulletListItem,
  form: Form,
  header: Header,
  image: Image,
  inlineStyledText: InlineStyledText,
  link: Link,
  list: List,
  listItem: ListItem,
  listSection: ListSection,
  paragraph: Paragraph,
  box: Box,
};

const navigationMap = {
  '/reauthenticate/redirect': {
    route: '/reauth/redirect',
  },
  '/redirect': {
    route: '/oauth/oem-redirect',
  },
  '/authorize/redirect': {
    route: '/oauth/redirect',
  },
};

const OemRedirect = ({
  componentTree, pageMetadata, setComponentTree,
}: IPageProps) => {
  const navigate = useNavigate();
  // send query params to backend for authorization
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const cleanedPath = stripTrailingSlashes(location.pathname) as
      | '/oauth/redirect'
      | '/reauth/redirect';

  const postCode = useCallback(async () => {
    const searchParams = new URLSearchParams(location.search);
    const requestBody = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [name, value] of searchParams) {
      requestBody[name] = value;
    }
    setIsLoading(true);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = await api.postBackend(navigationMap[cleanedPath].route, requestBody);

    setIsLoading(false);
    return result;
  }, []);

  useEffect(() => {
    postCode()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .then(({ data }) => {
        if (data.redirect) {
          window.location.href = data.redirect;
        } else if (data.routes) {
          navigate(data.routes.frontend);
        } else {
          setComponentTree(data);
        }
      })
      .catch((e: AxiosError) => {
        errorHandler(e);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setComponentTree(e.response?.data);
      });
  }, []);

  return isLoading || !(Array.isArray(componentTree) && componentTree.length > 0) ? (
    <div className="page-spinner">
      <span className="spinner" />
    </div>
  ) : (
    <Page
      componentMap={componentMap}
      componentTree={componentTree}
      pageMetadata={pageMetadata}
      setComponentTree={setComponentTree}
    />
  );
};

export default OemRedirect;
