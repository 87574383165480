import errorHandler from '../../../../services/sentry';

import { IBrandListItem } from '../../../../interfaces/components/brandList.interface';

import Circular from '../../../CircularBrandLogo';

const BrandListItem = ({
  logo,
  displayName,
  make,
  selectBrand,
  disabled,
}: IBrandListItem) => {
  const handleClick = () => {
    selectBrand(make).catch((e: Error | string) => errorHandler(e));
  };

  return (
    <button
      key={make}
      type="button"
      id={make}
      className="brand-list-item"
      onClick={handleClick}
      disabled={disabled}
    >
      <Circular make={make} logo={logo} displayName={displayName} />
      <div>
        <div className="brand-name">{displayName}</div>
      </div>
    </button>
  );
};

export default BrandListItem;
