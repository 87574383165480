/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import appConfig from 'appConfig';

import { FormContext } from '../Form/Form';

import { IFormContext } from '../../interfaces/components/form.interface';

const ReCAPTCHAComponent = () => {
  const context = useContext<IFormContext | undefined>(FormContext);
  const make = context?.formState.make;
  const siteKey = appConfig.googleRecaptchaV2SiteKey[make]
  || appConfig.googleRecaptchaV2SiteKey.fallback;

  return (
    <ReCAPTCHA
      ref={context?.formState.reCAPTCHARef}
      size="invisible"
      sitekey={siteKey}
      badge="bottomleft"
    />
  );
};

export default ReCAPTCHAComponent;
