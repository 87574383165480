import { useAppLoading } from '../../../../contexts/AppLoading';
import {
  IAccount,
} from '../../../../interfaces/components/accountSelector.interface';

import Circular from '../../../CircularBrandLogo';

const AccountSignedIn = ({
  sessionId,
  deviceId,
  username,
  brand,
  logo,
  make,
  handleReturnUserAction,
}: IAccount) => {
  const { appLoading } = useAppLoading();

  return (
    <button
      className="account"
      type="button"
      disabled={appLoading}
      onClick={() => handleReturnUserAction({ action: 'useAccount', deviceId, sessionId })}
    >
      <div className="account-info">
        <Circular make={make} logo={logo} displayName={brand} />
        <div className="text">
          <div className="brand">
            <h3>{brand}</h3>
          </div>
          <div className="username">
            <p>
              {username}
            </p>
          </div>
        </div>
      </div>
    </button>
  );
};

export default AccountSignedIn;
